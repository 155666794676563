import React, { useMemo, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { MdKeyboardArrowDown, MdCheck, MdSearch } from 'react-icons/md';
import classNames from 'classnames';
import { useFloating, flip, offset, getScrollParents } from '@floating-ui/react-dom';
import BaseInput from './BaseInput';

export interface Option<T> {
  key: T | null;
  value: string;
}

export type Props<T> = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'onChange' | 'value'
> & {
  options: Option<T>[];
  value?: T | null;
  onChange: (value: T | null) => void;
  disabled?: boolean;
  emptyOptionLabel?: string;
  required?: boolean;
  error?: string;
  className?: string;
};

export const SelectInput = <T extends unknown>({
  options,
  value,
  emptyOptionLabel = 'Selecione uma opção...',
  required,
  disabled,
  error,
  className,
  ...props
}: Props<T>) => {
  const [showListboxOptions, setShowListboxOptions] = React.useState(false);
  const { y, reference, floating, strategy, update, refs } = useFloating({
    placement: 'bottom',
    middleware: [
      flip({
        padding: 5,
      }),
      offset({
        mainAxis: 5,
      }),
    ],
  });

  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const refButtonElement = refs.reference.current as HTMLElement | null;
    switch (e.key) {
      case 'ArrowUp':
        if (optionsList[activeOption - 1]) {
          setActiveOption(activeOption - 1);
        }
        break;
      case 'ArrowDown':
        if (optionsList[activeOption + 1]) {
          setActiveOption(activeOption + 1);
        }
        break;
      case 'Enter':
        if (optionsList[activeOption] && refButtonElement) {
          props.onChange(optionsList[activeOption].key);
          setShowListboxOptions(false);
          refButtonElement.focus();
        }
        break;
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const menuEntries = React.useMemo(() => {
    const entries = [...options];

    if (!required) {
      entries.unshift({
        key: null,
        value: emptyOptionLabel,
      });
    }

    return entries;
  }, [options, emptyOptionLabel, required]);

  const [activeOption, setActiveOption] = useState(1);

  const optionsList = useMemo(() => {
    if (!menuEntries && optionsList.length > 0) return [];

    const filteredMenuEntries = menuEntries.filter((entry) => {
      return (
        entry.key === null ||
        entry.value
          .toLowerCase()
          .includes(searchValue?.toString().toLowerCase() || '')
      );
    });

    return filteredMenuEntries.map((entry, index) => {
      return {
        key: entry.key,
        value: entry.value,
        activeOption: index === activeOption,
      };
    });
  }, [menuEntries, searchValue, activeOption]);

  React.useEffect(() => {
    if (inputRef.current && showListboxOptions) {
      inputRef.current.focus();
    } else if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [showListboxOptions]);

  React.useEffect(() => {
    update();
  }, [optionsList, update]);

  const hasOptions = React.useMemo(() => options.length > 0, [options.length]);

  const selectedOptionLabel = React.useMemo(
    () =>
      hasOptions
        ? menuEntries.find((option) => option.key === value)?.value ||
          emptyOptionLabel
        : 'Nenhuma opção disponível',
    [hasOptions, menuEntries, emptyOptionLabel, value]
  );

  React.useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }
    const parents = [
      ...getScrollParents(refs.reference.current),
      ...getScrollParents(refs.floating.current),
    ];
    parents.forEach((parent) => {
      parent.addEventListener('scroll', update);
      parent.addEventListener('resize', update);
    });
    return () => {
      parents.forEach((parent) => {
        parent.removeEventListener('scroll', update);
        parent.removeEventListener('resize', update);
      });
    };
    // This actually can be disabled because the the refs are pointing to DOM nodes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs.reference.current, refs.floating.current, update]);

  const disableSelect = disabled || !hasOptions;

  const getButtonClassNames = () => {
    let extraClassNames;

    switch (true) {
      case disabled: {
        extraClassNames =
          'border-transparent bg-gray-dark700 text-gray-dark550 cursor-default';
        break;
      }
      case !!error: {
        extraClassNames = 'border-red-500 text-red-500 hover:text-red-500';
        break;
      }
      case !hasOptions: {
        extraClassNames =
          'border-gray-dark600 bg-gray-dark700 text-gray-dark500 cursor-default';
        break;
      }
      case !!value: {
        extraClassNames = 'bg-gray-dark600 border-gray-dark500 text-gray-dark400';
        break;
      }
      default: {
        extraClassNames =
          'bg-gray-dark600 border-gray-dark500 text-gray-dark500 placeholder-gray-dark500';
      }
    }

    return classNames(
      'px-3 py-2 w-full sm:text-sm border rounded-md text-left flex justify-between focus:outline-none focus:ring-primary focus:border-primary',
      extraClassNames
    );
  };

  return (
    <>
      <Listbox
        {...props}
        as="div"
        value={value}
        className={classNames('relative inline-block text-left w-full', className)}
      >
        {({ open }) => (
          <>
            <Listbox.Button
              className={getButtonClassNames()}
              disabled={disableSelect}
              ref={reference}
            >
              {showListboxOptions ? (
                <div className="flex items-center justify-start w-full">
                  <MdSearch
                    size={20}
                    color="#6B6B6B"
                    className={classNames('cursor-pointer mr-1.5', {
                      '!text-red-500': error,
                    })}
                  />
                  <BaseInput
                    {...props}
                    error={''}
                    type="text"
                    id={props.id}
                    value={searchValue}
                    ref={inputRef}
                    name={`SelectSearchInput${props.id}`}
                    autoComplete="off"
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder={
                      optionsList?.length === 0
                        ? 'Nenhuma opção disponível'
                        : props.placeholder
                    }
                    style={{ padding: 0 }}
                    className={classNames(
                      'appearence-none border-none focus:outline-none focus:ring-transparent w-full bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400 text-sm',
                      {
                        '!bg-transparent !text-red-500 !placeholder-red500': error,
                      }
                    )}
                  />
                </div>
              ) : (
                selectedOptionLabel
              )}
              <MdKeyboardArrowDown className="text-lg" />
            </Listbox.Button>
            {!disableSelect && (
              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                beforeEnter={() => setShowListboxOptions(true)}
                afterLeave={() => setShowListboxOptions(false)}
              >
                {showListboxOptions && (
                  <Listbox.Options
                    className="left-0 origin-top-left bg-gray-dark600 rounded-md w-full max-h-[308px] overflow-y-auto focus:outline-none shadow-2xl z-20 scrollbar border border-gray-dark500"
                    style={{
                      position: strategy,
                      top: y ?? '',
                    }}
                    ref={floating}
                    data-testid="select-input_popup"
                  >
                    {optionsList.map(({ key, value: label, activeOption }) => (
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            'text-gray-dark500 text-left cursor-pointer outline-none hover:bg-gray-dark550',
                            {
                              'border-b border-gray-dark500': key === null,
                              '!text-gray-dark400 bg-gray-dark550':
                                active || activeOption,
                            }
                          )
                        }
                        value={key}
                        key={label}
                      >
                        {({ selected }) => (
                          <span
                            className={classNames(
                              'px-3 py-3 sm:text-sm flex justify-between',
                              {
                                'text-gray-dark400 font-semibold': selected,
                              }
                            )}
                          >
                            {label}
                            {selected && (
                              <MdCheck className="text-lg font-semibold" />
                            )}
                          </span>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                )}
              </Transition>
            )}
          </>
        )}
      </Listbox>
      <div />
      {error && <p className="text-red-500 text-xs mt-3">{error}</p>}
    </>
  );
};

export default SelectInput;
