import { useMemo, useState } from 'react';

import {
  Control,
  useWatch,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { MaskInterface } from 'utils/masks';

export type FormFilters = {
  groupName: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  cooperativeLegalName: string;
  generationUnitLegalName: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export default function ContactGroupsFormFilters({
  control,
  register,
  setValue,
  isLoading,
}: {
  isLoading?: boolean;
  control: Control<FormFilters>;
  setValue: UseFormSetValue<FormFilters>;
  register: UseFormRegister<FormFilters>;
}) {
  const GROUP_TEXT_FILTERS = useMemo(
    () => [
      {
        label: 'Nome',
        placeholder: 'Digitar',
        id: 'groupName' as const,
      },
    ],
    []
  );

  const CONTACT_TEXT_FILTERS = useMemo(
    () => [
      {
        label: 'Nome',
        placeholder: 'Digitar',
        id: 'contactName' as const,
        mask: 'textOnly' as MaskInterface,
      },
      {
        label: 'Telefone',
        placeholder: 'Digitar',
        id: 'contactPhone' as const,
        mask: 'mobilePhone' as MaskInterface,
      },
      {
        label: 'E-mail',
        placeholder: 'Digitar',
        id: 'contactEmail' as const,
        mask: '' as MaskInterface,
      },
      {
        label: 'Unidade Consumidora',
        placeholder: 'Digitar',
        id: 'consumerUnitPowerDistributionUnitIdentifier' as const,
        mask: '' as MaskInterface,
      },
    ],
    []
  );

  const activeFiltersCount = useWatch({
    control,
    name: [
      'groupName',
      'contactName',
      'contactPhone',
      'contactEmail',
      'cooperativeLegalName',
      'generationUnitLegalName',
      'consumerUnitPowerDistributionUnitIdentifier',
    ],
  }).filter((filter) => filter).length;

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  function handleFiltersOpen() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  function handleClearFilters() {
    GROUP_TEXT_FILTERS.forEach((filter) => setValue(filter.id, ''));
    CONTACT_TEXT_FILTERS.forEach((filter) => setValue(filter.id, ''));
  }

  return (
    <div className="w-full flex flex-col gap-4 items-start justify-start mb-3">
      <div className="flex flex-col gap-1">
        <p className="font-bold text-gray-dark400">Filtros avançados</p>
        <Button
          size="md"
          type="button"
          variant="advancedFilter"
          onClick={handleFiltersOpen}
          className="flex items-center justify-center"
        >
          <p className="text-sm font-medium">Filtros ({activeFiltersCount})</p>
          {isFiltersOpen ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </Button>
      </div>
      {isFiltersOpen && (
        <div className="w-full flex flex-col gap-4 items-start justify-start bg-gray-dark700 rounded-lg">
          <div className="w-full flex gap-12 items-stretch justify-start p-7">
            <div className="w-full flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Dados do grupo</p>
              {GROUP_TEXT_FILTERS.map((group) => (
                <TextField
                  id={group.id}
                  key={group.id}
                  label={group.label}
                  wrapperClassName="w-full"
                  placeholder={group.placeholder}
                  {...register(group.id)}
                />
              ))}
            </div>

            <div className="w-full flex flex-col items-start justify-start gap-4">
              <p className="font-bold text-gray-dark400">Dados de contato</p>
              {CONTACT_TEXT_FILTERS.slice(0, 1).map((contact) => (
                <TextField
                  id={contact.id}
                  key={contact.id}
                  label={contact.label}
                  wrapperClassName="w-full"
                  placeholder={contact.placeholder}
                  {...register(contact.id)}
                />
              ))}
              <div className="w-full flex items-start justify-start gap-4">
                {CONTACT_TEXT_FILTERS.slice(1, 3).map((contact) => (
                  <TextField
                    id={contact.id}
                    key={contact.id}
                    mask={contact.mask}
                    label={contact.label}
                    wrapperClassName="w-full"
                    placeholder={contact.placeholder}
                    {...register(contact.id)}
                  />
                ))}
              </div>
            </div>

            <div className="w-full flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Selecionar Entidades</p>
              <div className="w-full flex items-start justify-start gap-4">
                {CONTACT_TEXT_FILTERS.slice(3, 4).map((contact) => (
                  <TextField
                    id={contact.id}
                    key={contact.id}
                    mask={contact.mask}
                    label={contact.label}
                    wrapperClassName="w-full"
                    placeholder={contact.placeholder}
                    {...register(contact.id)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-between border-t border-gray-dark500 p-7">
            <p className="font-bold flex text-gray-dark500 w-full">
              ({activeFiltersCount}) Filtros selecionados
            </p>
            <div className="w-full flex gap-4 items-center justify-end">
              <Button
                size="sm"
                type="submit"
                className="w-24"
                disabled={isLoading}
                variant="secondaryGray"
                onClick={handleClearFilters}
              >
                Limpar
              </Button>
              <Button
                size="sm"
                type="submit"
                disabled={isLoading || !activeFiltersCount}
              >
                {`Aplicar ${activeFiltersCount} filtros`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
